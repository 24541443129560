import React, { Component } from "react";
import { Container, Divider } from "semantic-ui-react";
import BloqHandler from "./BloqHandler";

class BloqDivider extends Component {
  render() {
    const data = this.props.data;
    var hidden = false;
    var horizontal = false;
    if (data.visibility === "hidden") {
      hidden = true;
    }
    if (data.nested || data.dividerText) {
      horizontal = true;
    }

    return (
      <Container>
        {data.nested ? (
          <Divider
            horizontal
            hidden={hidden}
            style={{ margin: `${data.margins}` }}
            className={data.color}
          >
            {data.nested.map((bloq) => (
              <BloqHandler data={bloq} key={bloq.bloqId} />
            ))}
          </Divider>
        ) : (
          <Divider
            horizontal={horizontal}
            hidden={hidden}
            style={{ margin: `${data.margins}` }}
          >
            {data.dividerText}
          </Divider>
        )}
      </Container>
    );
  }
}

export default BloqDivider;
