import React, { Component } from "react";
import { Breadcrumb, Container, Grid } from "semantic-ui-react";

class PageCrumbs extends Component {
  render() {
    const parents = this.props.data;
    if (parents.length > 1) {
      return (
        <Container style={{ margin: "1em 0" }}>
          <Grid>
            <Grid.Column width={16}>
              <Breadcrumb icon="right angle" sections={parents} />
            </Grid.Column>
          </Grid>
        </Container>
      );
    } else {
      return null;
    }
  }
}

export default PageCrumbs;
