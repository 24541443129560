import React, { Component } from "react";
import { Accordion, Menu } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";

class MobileMenu extends Component {
  constructor(props) {
    super();
    this.state = { activeIndex: 0 };

    // const Level1AContent = (
    //   <div>
    //     <Menu.Item as={NavLink} to="/about">
    //       Why Buy from Us
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/about">
    //       Policies
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/about">
    //       Blah blah
    //     </Menu.Item>
    //   </div>
    // );
    // const Level1BContent = (
    //   <div>
    //     <Menu.Item as={NavLink} to="/about">
    //       Intro to Hedgies
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/about">
    //       Care
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/about">
    //       Diet
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/about">
    //       Blah blah blah
    //     </Menu.Item>
    //   </div>
    // );

    // const level1Panels = [
    //   {
    //     key: "panel-1a",
    //     title: "About Us",
    //     content: { content: Level1AContent },
    //   },
    //   {
    //     key: "panel-1b",
    //     title: "Hedgehogs 101",
    //     content: { content: Level1BContent },
    //   },
    // ];

    // const Level1Content = (
    //   <div>
    //     <Accordion.Accordion panels={level1Panels} />
    //   </div>
    // );

    // const Level2AContent = (
    //   <div>
    //     <Menu.Item
    //       as={NavLink}
    //       to="/hedgehogs-for-sale/"
    //       onClick={this.handleNavItemClick}
    //     >
    //       For Sale
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/hedgehogs-for-sale/category/on-hold">
    //       On Hold
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/hedgehogs-for-sale/category/sold">
    //       Sold
    //     </Menu.Item>
    //   </div>
    // );

    // const level2Panels = [
    //   {
    //     key: "panel-2a",
    //     title: "Babies",
    //     content: { content: Level2AContent },
    //   },
    // ];

    // const Level2Content = (
    //   <div>
    //     <Accordion.Accordion panels={level2Panels} />
    //     <Menu.Item as={NavLink} to="/hedgehogs-for-sale/category/adult-rehomes">
    //       Adults
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/upcoming-litters">
    //       Upcoming Litters
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/about/pricing-list">
    //       Pricing List
    //     </Menu.Item>
    //     <Menu.Item as={NavLink} to="/waiting-list">
    //       Waiting List
    //     </Menu.Item>
    //   </div>
    // );

    // this.rootPanels = [
    //   // { key: "panel-1", title: "About", content: { content: Level1Content } },
    //   {
    //     key: "panel-2",
    //     title: "Available Hedgehogs",
    //     content: { content: Level2Content },
    //   },
    // ];
  }

  render() {
    const rootPanels = this.rootPanels;
    const handleNavItemClick = this.props.handleNavItemClick;
    var theActiveIndex = this.state.activeIndex;
    return (
      <Accordion as={Menu} fluid vertical activeIndex={theActiveIndex} styled>
        <Menu.Item as={NavLink} to="/about" onClick={handleNavItemClick}>
          About
        </Menu.Item>
        {/* <Accordion.Accordion
          panels={rootPanels}
          handleNavItemClick={handleNavItemClick}
        /> */}
        <Menu.Item
          as={NavLink}
          to="/hedgehogs-for-sale"
          onClick={handleNavItemClick}
        >
          Available Hedgehogs
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/our-hedgehogs"
          onClick={handleNavItemClick}
        >
          Our Hedgehogs
        </Menu.Item>
        <Menu.Item as={NavLink} to="/supplies" onClick={handleNavItemClick}>
          Supplies
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/blog-coming-soon"
          name="blog"
          onClick={handleNavItemClick}
        >
          Blog
        </Menu.Item>
        <Menu.Item as={NavLink} to="/about/faq" onClick={handleNavItemClick}>
          FAQ
        </Menu.Item>
        {/* <Menu.Item as={NavLink} to="/about">
          Contact
        </Menu.Item> */}
        <Menu.Item as={NavLink} to="/cart" onClick={handleNavItemClick}>
          Cart {this.props.cartQuantity}
        </Menu.Item>
      </Accordion>
    );
  }
}

export default MobileMenu;
