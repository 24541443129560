import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

class BloqButton extends Component {
  render() {
    const data = this.props.data;
    var icony = false;
    var linkUrl = new URL(`${data.href}`);
    var linkhref;
    if (data.style === "icon--exploded") {
      icony = true;
    }
    if (linkUrl.origin === "http://suncoasthedgehogs.com") {
      linkhref = linkUrl.pathname;
      return (
        <Button
          key={data.blockId}
          icon={icony}
          labelPosition={icony ? "left" : null}
          as={Link}
          to={linkhref}
          color={data.color}
          size={data.size}
        >
          {data.icon &&
          (data.style === "icon--front" || data.style === "icon--exploded") ? (
            <Icon name={data.icon} />
          ) : null}
          {ReactHtmlParser(data.text)}
          {data.icon && data.style === "default" ? (
            <Icon name={data.icon} />
          ) : null}
        </Button>
      );
    } else {
      linkhref = data.href;
      return (
        <a
          key={data.blockId}
          href={linkhref}
          className={`ui button ${icony ? "icon left labeled" : null} ${
            data.color
          } ${data.size}`}
        >
          {data.icon &&
          (data.style === "icon--front" || data.style === "icon--exploded") ? (
            <Icon name={data.icon} />
          ) : null}
          {ReactHtmlParser(data.text)}
          {data.icon && data.style === "default" ? (
            <Icon name={data.icon} />
          ) : null}
        </a>
      );
    }
  }
}

export default BloqButton;
