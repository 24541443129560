import React, { Component } from "react";
import { Image } from "semantic-ui-react";

class BloqImage extends Component {
  render() {
    const data = this.props.data;
    const url = data.href;

    if (url !== "") {
      return (
        <a href={data.href}>
          <Image
            key={data.blockId}
            src={data.src}
            alt={data.altText}
            className={`${data.position} ${data.style}`}
          />
        </a>
      );
    } else {
      return (
        <Image
          key={data.blockId}
          src={data.src}
          alt={data.altText}
          className={`${data.position} ${data.style}`}
        />
      );
    }
  }
}

export default BloqImage;
