import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import PageMeta from "./PageMeta";
//import Loading from "./Loader";
//import HeroSlider from "./HeroSlider";
import Slider from "react-slick";
//import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Responsive,
} from "semantic-ui-react";

class ColorChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMeta: {},
      breadcrumbs: {},
      bloqs: {},
    };
  }

  // componentDidMount() {
  //   const handle = this.props.match.params.handle;
  //   axios
  //     .get(`https://admin.suncoasthedgehogs.com/eeData/pageContent/shopping-cart`)
  //     .then((page) => {
  //       this.setState({
  //         pageMeta: page.data.pageMeta[0],
  //         breadcrumbs: page.data.breadcrumbs,
  //         bloqs: page.data.blocks,
  //       });
  //     });
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.match.params.handle !== this.props.match.params.handle) {
  //     this.setState({
  //       pageMeta: {},
  //       breadcrumbs: [{}],
  //       bloqs: {},
  //     });
  //     axios
  //       .get(
  //         `https://admin.suncoasthedgehogs.com/eeData/pageContent/shopping-cart`
  //       )
  //       .then((page) => {
  //         this.setState({
  //           pageMeta: page.data.pageMeta[0],
  //           breadcrumbs: page.data.breadcrumbs,
  //           bloqs: page.data.blocks,
  //         });
  //       });
  //   }
  // }

  render() {
    // const meta = this.state.pageMeta;
    // const crumbs = this.state.breadcrumbs;

    var chartSlider = {
      className: "chartSlider",
      arrows: true,
      dots: false,
      swipe: false,
      draggable: true,
      responsive: [
        {
          breakpoint: 1920,
          slidesToShow: 2,
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    //const bloqs = this.state.bloqs;
    //  const {
    //    checkout,
    //    clearCart,
    //    removeVariant,
    //    updateVariantQuantity,
    //  } = this.props;

    //  if (meta.title) {
    return (
      <Grid.Row as={this.props.slider ? Slider : Grid.Row} {...chartSlider}>
        <Grid.Column width="4">
          <Header as="h3" size="small">
            Base Color
          </Header>
          <Slider {...sliderSettings} className="colorSlider">
            <Image src="https://placehold.it/500x500" />
            <Image src="https://placehold.it/400x400" />
            <Image src="https://placehold.it/600x600" />
          </Slider>
          <div className="colorChart__content">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex
              tenetur consequatur laudantium magnam, quidem ea tempore dolor
              asperiores. Sint sapiente excepturi quod unde minima corrupti
              voluptates molestias rerum, sit laboriosam!
            </p>
          </div>
        </Grid.Column>
        <Grid.Column width="3">
          <Header as="h3" size="small">
            Snowflake
          </Header>
          <Slider {...sliderSettings} className="colorSlider">
            <Image src="https://placehold.it/500x500" />
            <Image src="https://placehold.it/400x400" />
            <Image src="https://placehold.it/600x600" />
          </Slider>
          <div className="colorChart__content">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex
              tenetur consequatur laudantium magnam, quidem ea tempore dolor
              asperiores. Sint sapiente excepturi quod unde minima corrupti
              voluptates molestias rerum, sit laboriosam!
            </p>
          </div>
        </Grid.Column>
        <Grid.Column width="3">
          <Header as="h3" size="small">
            Pinto
          </Header>
          <Slider {...sliderSettings} className="colorSlider">
            <Image src="https://placehold.it/500x500" />
            <Image src="https://placehold.it/400x400" />
            <Image src="https://placehold.it/600x600" />
          </Slider>
          <div className="colorChart__content">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex
              tenetur consequatur laudantium magnam, quidem ea tempore dolor
              asperiores. Sint sapiente excepturi quod unde minima corrupti
              voluptates molestias rerum, sit laboriosam!
            </p>
          </div>
        </Grid.Column>
        <Grid.Column width="3">
          <Header as="h3" size="small">
            Markings
          </Header>
          <Slider {...sliderSettings} className="colorSlider">
            <Image src="https://placehold.it/500x500" />
            <Image src="https://placehold.it/400x400" />
            <Image src="https://placehold.it/600x600" />
          </Slider>
          <div className="colorChart__content">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex
              tenetur consequatur laudantium magnam, quidem ea tempore dolor
              asperiores. Sint sapiente excepturi quod unde minima corrupti
              voluptates molestias rerum, sit laboriosam!
            </p>
          </div>
        </Grid.Column>
        <Grid.Column width="3">
          <Header as="h3" size="small">
            Albino
          </Header>
          <Slider {...sliderSettings} className="colorSlider">
            <Image src="https://placehold.it/500x500" />
            <Image src="https://placehold.it/400x400" />
            <Image src="https://placehold.it/600x600" />
          </Slider>
          <div className="colorChart__content">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex
              tenetur consequatur laudantium magnam, quidem ea tempore dolor
              asperiores. Sint sapiente excepturi quod unde minima corrupti
              voluptates molestias rerum, sit laboriosam!
            </p>
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default ColorChart;
