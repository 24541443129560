import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import PageMeta from "../components/PageMeta";
import PageContent from "../components/PageContent";
import Product from "../components/Product";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

class ProductLayout extends Component {
  constructor(props) {
    const pathSegment = props.location.pathname;
    super(props);
    this.state = {
      pageMeta: {
        headerBgColor: "gradient-warm",
        title: "Supplies",
      },
      breadcrumbs: [{ content: "Home" }, { content: "Supplies" }],
    };
  }

  handleMeta = (breadcrumbValue) => {
    this.setState({ breadcrumbs: breadcrumbValue });
  };

  render() {
    const meta = this.state.pageMeta;
    const crumbs = this.state.breadcrumbs;
    const { addVariant } = this.props;
    return (
      <Fragment>
        <ScrollToTopOnMount />
        <PageMeta path="/supplies" meta={meta} crumbs={crumbs} />
        <Switch>
          <Route
            exact
            path={this.props.match.path}
            render={(props) => (
              <PageContent
                {...props}
                onPathChange={this.handleMeta}
                handle="/supplies"
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/:handle`}
            render={(props) => (
              <Product
                {...props}
                onPathChange={this.handleMeta}
                addVariant={addVariant}
              />
            )}
          />
          {/* <Route
            path={`${props.match.path}/category/:handle`}
            exact
            render={(props) => <HedgieList {...props} category />}
          /> */}
        </Switch>
      </Fragment>
    );
  }
}

export default ProductLayout;
