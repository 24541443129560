import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "react-apollo";

import "semantic-ui-less/semantic.less";

const httpLink = createHttpLink({
  uri:
    // "https://sun-coast-hedgehogs-test.myshopify.com/api/2023-07/graphql.json",
    "https://sun-coast-hedgehogs.myshopify.com/api/2023-07/graphql.json",
});

const middlewareLink = setContext(() => ({
  headers: {
    // "X-Shopify-Storefront-Access-Token": "9dd1223b76d19969199c57047ffd3521",
    "X-Shopify-Storefront-Access-Token": "af21a3bb0fc32021aed14c378e0f35ef",
  },
}));

const client = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
