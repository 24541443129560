import React, { Component, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Card, Image, Loader, Segment } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import WaitlistCard from "./WaitlistCard";

class WaitlistsAsCards extends Component {
  constructor() {
    super();
    this.state = {
      waitlists: [],
    };
  }

  componentDidMount() {
    axios
      .get(`https://admin.suncoasthedgehogs.com/eeData/waitlists`)
      .then((waitlists) => {
        this.setState({
          waitlists: waitlists.data,
        });
      });
  }

  render() {
    if (this.state.waitlists[0]) {
      if (this.state.waitlists[0].no_results) {
        return (
          <Segment inverted color="yellow">
            <p>{this.state.waitlists[0].no_results}</p>
          </Segment>
        );
      } else {
        return (
          <Fragment>
            {this.state.waitlists.map((waitlist) => (
              <Card>
                <Link to={`/supplies/${waitlist.handle}`}>
                  <Image
                    src={
                      waitlist.handle === "vip-waiting-list"
                        ? "https://admin.suncoasthedgehogs.com/images/uploads/misc/sch-pics-premiumwaitlist-v1.gif"
                        : "https://admin.suncoasthedgehogs.com/images/uploads/misc/sch-pics-waitlist-v1.gif"
                    }
                    alt={waitlist.name}
                  />
                </Link>
                <Card.Content>
                  {/* <Card.Header>{hedgie.name}</Card.Header> */}
                  <dl className="hedgieMeta">
                    <div className="dl__item">
                      <dt>Name</dt>
                      <dd>{ReactHtmlParser(waitlist.name)}</dd>
                    </div>
                    <div className="dl__item">
                      <dt>Spots Left</dt>
                      <dd>
                        {waitlist.totalSlots - waitlist.filledSlots === 0
                          ? "SOLD OUT"
                          : waitlist.totalSlots - waitlist.filledSlots}
                      </dd>
                    </div>
                  </dl>
                </Card.Content>
                <Card.Content extra>
                  <span className="price">${waitlist.price}</span>
                  <Button
                    primary
                    size="mini"
                    as={Link}
                    to={`/supplies/${waitlist.handle}`}
                  >
                    Claim Your Spot
                  </Button>
                </Card.Content>
              </Card>
            ))}
          </Fragment>
        );
      }
    } else {
      return <Loader />;
    }
  }
}

export default WaitlistsAsCards;
