import React, { Component } from "react";
import {
  Container,
  Grid,
  Header,
  Image,
  Responsive,
  Segment,
} from "semantic-ui-react";

class PageHeader extends Component {
  render() {
    const meta = this.props.meta;
    const crumbs = this.props.crumbs;
    const bgColor = this.props.meta.headerBgColor;

    var imageRatio = meta.headerImageHeight / meta.headerImageWidth;
    var imageScale = "";
    if (imageRatio >= 0.85 && imageRatio <= 1.25) {
      imageScale = "50%";
    } else if (imageRatio < 0.85) {
      imageScale = "75%";
      if (imageRatio < 0.65) {
        imageScale = "100%";
      }
    } else {
      imageScale = "40%";
      if (imageRatio > 1.45) {
        imageScale = "25%";
      }
    }

    if (crumbs.length > 1) {
      return (
        <Segment
          inverted
          basic
          color={bgColor}
          className={bgColor}
          style={{
            margin: "0 auto",
            backgroundImage: `url("${meta.headerBgImage}")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Container>
            <Grid centered>
              <Responsive
                as={Grid.Column}
                maxWidth={767}
                mobile={imageRatio <= 0.75 ? 12 : 6}
                tablet={6}
                computer={7}
              >
                <Image as={Image} src={meta.headerImage} />
              </Responsive>
              <Grid.Column mobile={16} tablet={9} computer={8}>
                <Header
                  inverted
                  as="h1"
                  className={`headerHeading ${
                    meta.subtitle ? "subtitled" : null
                  }`}
                >
                  {meta.titleOverride ? meta.titleOverride : meta.title}
                  {meta.subtitle ? (
                    <Header.Subheader>{meta.subtitle}</Header.Subheader>
                  ) : null}
                </Header>
              </Grid.Column>
              <Responsive
                as={Grid.Column}
                minWidth={768}
                mobile={12}
                tablet={7}
                computer={8}
                style={{
                  backgroundImage: `url("${meta.headerImage}")`,
                  backgroundPosition: `${
                    meta.headerImagePosition
                      ? meta.headerImagePosition
                      : "center"
                  }`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: `${imageScale}`,
                  minHeight: "5em",
                }}
              ></Responsive>
            </Grid>
          </Container>
        </Segment>
      );
    } else {
      return null;
    }
  }
}

export default PageHeader;
