import React, { Component } from "react";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";

class BlogCategoryMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    axios
      .get(`https://admin.suncoasthedgehogs.com/eeData/blogCategories/`)
      .then((categories) => {
        this.setState({
          categories: categories.data,
        });
      });
  }

  render() {
    return (
      <Menu vertical fluid className="sideMenu">
        {this.state.categories.map((category) => (
          <Menu.Item
            as={NavLink}
            name={ReactHtmlParser(category.name)}
            to={`/blog/category/${category.slug}`}
          />
        ))}
      </Menu>
    );
  }
}

export default BlogCategoryMenu;
