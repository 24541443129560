import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class BloqRTE extends Component {
  render() {
    const data = this.props.data;

    return <div className="bloq_RTE">{ReactHtmlParser(data.content)}</div>;
  }
}

export default BloqRTE;
