import React, { Component } from "react";
import HedgiesLoop from "../components/HedgiesLoop";

import moment from "moment";

class BloqLoopHedgies extends Component {
  render() {
    var currentDate = moment().format("MM/DD/YY");
    const channel = this.props.channel;
    const bloq = this.props.data;
    return <HedgiesLoop channel={channel} data={bloq} date={currentDate} />;
    //<HedgiesLoop data={bloq} />;
  }
}

export default BloqLoopHedgies;
