import React, { Component, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Card, Image, Segment } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

class WaitlistCard extends Component {
  render() {
    const waitlist = this.props.waitlist;
    const availableSlots = this.props.availableSlots;
    const cards = [];

    for (let i = 0; i < availableSlots; i++) {
      cards.push(
        <Card>
          <Link to={`/supplies/${waitlist.handle}`}>
            <Image
              src={
                waitlist.image
                  ? waitlist.image
                  : "https://admin.suncoasthedgehogs.com/images/uploads/hedgehogs/sch-placeholder2.jpg"
              }
              alt={waitlist.name}
            />
          </Link>
          <Card.Content>
            {/* <Card.Header>{hedgie.name}</Card.Header> */}
            <dl className="hedgieMeta">
              <div className="dl__item">
                <dt>Name</dt>
                <dd>{ReactHtmlParser(waitlist.name)}</dd>
              </div>
            </dl>
          </Card.Content>
          <Card.Content extra>
            <span className="price">${waitlist.price}</span>
            <Button primary size="mini" as={Link} to="/waiting-list">
              Claim Your Spot
            </Button>
          </Card.Content>
        </Card>
      );
    }

    return <Fragment>{cards}</Fragment>;
  }
}

export default WaitlistCard;
