import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Grid } from "semantic-ui-react";
import PageMeta from "../components/PageMeta";
import BlogList from "../components/BlogList";
import BlogView from "../components/BlogView";
import BlogCategoryMenu from "../components/BlogCategoryMenu";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

class BlogLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMeta: { headerBgColor: "gradient-warm", title: "Blog" },
      breadcrumbs: [{ content: "Home" }, { content: "Blog" }],
    };
  }

  handleCrumbs = (breadcrumbValue) => {
    this.setState({ breadcrumbs: breadcrumbValue });
  };

  render() {
    const meta = this.state.pageMeta;
    const crumbs = this.state.breadcrumbs;
    const { match } = this.props;
    return (
      <Fragment>
        <ScrollToTopOnMount />
        <PageMeta path="blog" meta={meta} crumbs={crumbs} />
        <Container style={{ padding: "3em 0" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={3}>
                <BlogCategoryMenu />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={13}>
                <Switch>
                  <Route
                    exact
                    path={`${match.path}/post/:handle`}
                    render={(props) => (
                      <BlogView {...props} onPathChange={this.handleCrumbs} />
                    )}
                  />
                  <Route
                    exact
                    path={`${match.path}/:page?`}
                    render={(props) => (
                      <BlogList {...props} onPathChange={this.handleCrumbs} />
                    )}
                  />
                  <Route
                    exact
                    path={`${match.path}/category/:handle/:page?`}
                    render={(props) => (
                      <BlogList
                        {...props}
                        category
                        onPathChange={this.handleCrumbs}
                      />
                    )}
                  />
                </Switch>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default BlogLayout;
