import React, { Component, Fragment } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Segment,
} from "semantic-ui-react";

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: "",
    };
  }

  render() {
    return (
      <Fragment>
        <Segment className="gradient-cool">
          <Grid centered padded verticalAlign="middle">
            <Grid.Row>
              <Grid.Column
                mobile={14}
                tablet={12}
                computer={12}
                textAlign="center"
              >
                <Header
                  as="h2"
                  className="upper"
                  content="Join Our Mailing List!"
                  subheader="For more cute hedgies in your inbox!"
                />
                <Form
                  action="https://suncoasthedgehogs.us12.list-manage.com/subscribe/post?u=bf98679e6f7167233addd7f81&amp;id=1906990511"
                  method="POST"
                  success
                  noValidate
                  size="massive"
                >
                  <input
                    type="hidden"
                    name="u"
                    value="bf98679e6f7167233addd7f81"
                  />
                  <input type="hidden" name="id" value="1906990511" />

                  <Input
                    fluid
                    className="emailActionForm"
                    name="EMAIL"
                    id="MERGE0"
                    icon="mail"
                    iconPosition="left"
                    control="input"
                    type="email"
                    placeholder="example@email.com"
                    action={{
                      color: "red",
                      content: "Sign up!",
                    }}
                  />
                  {/* <Form.Field>
                      <Button size="massive" primary content="Sign up!" fluid />
                    </Form.Field> */}
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                    aria-label="Please leave the following three fields empty"
                  >
                    <label htmlFor="b_email">Email: </label>
                    <input
                      type="email"
                      name="b_email"
                      tabIndex="-1"
                      value=""
                      placeholder="youremail@gmail.com"
                      id="b_email"
                    />
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_bf98679e6f7167233addd7f81_1906990511"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <div class="clear">
                      <input
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        class="button"
                      />
                    </div>
                  </div>
                  {/* <Form.Input label="Email Address" type="email" /> */}
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        {/*<form
          action="https://suncoasthedgehogs.us12.list-manage.com/subscribe/post?u=bf98679e6f7167233addd7f81&amp;id=1906990511"
          method="POST"
          noValidate
        >
          <input type="hidden" name="u" value="bf98679e6f7167233addd7f81" />
          <input type="hidden" name="id" value="1906990511" />
          <label htmlFor="MERGE0">
            Email
            <input
              type="email"
              name="EMAIL"
              id="MERGE0"
              value={this.state.emailValue}
              onChange={(e) => {
                this.setState({ emailValue: e.target.value });
              }}
              autoCapitalize="off"
              autoCorrect="off"
            />
          </label>
          <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="button"
          />

          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
            aria-label="Please leave the following three fields empty"
          >
            <label htmlFor="b_email">Email: </label>
            <input
              type="email"
              name="b_email"
              tabIndex="-1"
              value=""
              placeholder="youremail@gmail.com"
              id="b_email"
            />
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_bf98679e6f7167233addd7f81_1906990511"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              />
            </div>
          </div>
        </form> */}
      </Fragment>
    );
  }
}

export default SignupForm;
