import React, { Component } from "react";

import { Card, Segment } from "semantic-ui-react";
import HedgieCard from "./HedgieCard";
import PlaceholderHedgieCard from "./PlaceholderHedgieCard";
import WaitlistNag from "./WaitlistNag";

class HedgiesLoop extends Component {
  render() {
    const channel = this.props.channel;
    const hedgies = this.props.data;

    if (hedgies[0]) {
      if (hedgies[0].no_results) {
        if (channel == "for-sale") {
          return <WaitlistNag fallback={hedgies[0].no_results} />;
        } else {
          return (
            <Segment inverted color="yellow">
              <p>{hedgies[0].no_results}</p>
            </Segment>
          );
        }
      } else {
        return (
          <Card.Group
            itemsPerRow={4}
            doubling
            stackable
            className="hedgieTeaser"
          >
            {hedgies.map((hedgie) => (
              <HedgieCard
                hedgie={hedgie}
                herd={channel === "hedgies_herd" ? true : false}
                key={hedgie.id}
                currentDate={this.props.currentDate}
              />
              //<p>HedgieCard</p>
            ))}
          </Card.Group>
        );
      }
    } else {
      return (
        <Card.Group itemsPerRow={4} className="hedgieTeaser">
          <PlaceholderHedgieCard />
          <PlaceholderHedgieCard />
          <PlaceholderHedgieCard />
          <PlaceholderHedgieCard />
        </Card.Group>
      );
    }
  }
}

export default HedgiesLoop;
