import React, { Component, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Placeholder,
  Card,
  Divider,
  Segment,
} from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import Loader from "./Loader";
import HedgieCard from "./HedgieCard";

class HedgieArchive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pageMeta: {
        headerBgColor: "gradient-warm",
        title: "Available Hedgehogs",
      },
      breadcrumbs: [{ content: "Home" }, { content: "Available Hedgehogs" }],
      featuredPosts: [{}],
      hedgies: [{}, {}, {}, {}, {}, {}],
      paginate: {
        totalPages: 1,
        currentPage: 1,
        pages: [
          {
            type: "firstItem",
          },
          {
            type: "pageItem",
            number: 1,
          },
          {
            type: "lastItem",
          },
        ],
      },
    };
  }

  handlePathChange = () => {
    var crumbs = this.state.breadcrumbs;
    this.props.onPathChange(crumbs);
  };

  componentDidMount() {
    if (this.props.category) {
      const apiUrl =
        "https://admin.suncoasthedgehogs.com/eeData/hedgieArchives/";
      const catUrl = this.props.match.params.handle;
      const pageUrl = this.props.match.params.page * 48 - 48;
      const getUrl = apiUrl + "category/" + catUrl + "/P" + pageUrl;
      axios.get(getUrl).then((hedgieArchive) => {
        console.log(hedgieArchive);
        this.setState({
          loading: false,
          pageMeta: hedgieArchive.data.pageMeta,
          breadcrumbs: hedgieArchive.data.breadcrumbs,
          hedgies: hedgieArchive.data.hedgies,
          paginate: hedgieArchive.data.paginate[0],
        });
        console.log(getUrl);
        this.handlePathChange();
      });
    } else {
      const apiUrl =
        "https://admin.suncoasthedgehogs.com/eeData/hedgieArchives/";
      const pageUrl = this.props.match.params.page * 48 - 48;
      const getUrl = apiUrl + "P" + pageUrl;

      axios.get(getUrl).then((hedgieArchive) => {
        console.log(hedgieArchive);
        this.setState({
          loading: false,
          pageMeta: hedgieArchive.data.pageMeta,
          breadcrumbs: hedgieArchive.data.breadcrumbs,
          hedgies: hedgieArchive.data.hedgies,
          paginate: hedgieArchive.data.paginate[0],
        });
        console.log(getUrl);
        this.handlePathChange();
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.handle !== this.props.match.params.handle ||
      nextProps.match.params.page !== this.props.match.params.page
    ) {
      this.setState({
        loading: true,
        pageMeta: {
          headerBgColor: "gradient-warm",
          title: "Available Hedgehogs",
        },
        breadcrumbs: [{ content: "Home" }, { content: "Available Hedgehogs" }],
        featuredPosts: [{}],
        hedgies: [{}, {}, {}, {}, {}, {}],
      });
      if (nextProps.category) {
        const apiUrl =
          "https://admin.suncoasthedgehogs.com/eeData/hedgieArchives/";
        const catUrl = nextProps.match.params.handle;
        const pageUrl = nextProps.match.params.page * 48 - 48;
        const getUrl = apiUrl + "category/" + catUrl + "/P" + pageUrl;
        axios.get(getUrl).then((hedgieArchive) => {
          console.log(hedgieArchive);
          this.setState({
            loading: false,
            pageMeta: hedgieArchive.data.pageMeta,
            breadcrumbs: hedgieArchive.data.breadcrumbs,
            hedgies: hedgieArchive.data.hedgies,
            paginate: hedgieArchive.data.paginate[0],
          });
          this.handlePathChange();
        });
      } else {
        const apiUrl =
          "https://admin.suncoasthedgehogs.com/eeData/hedgieArchives/";
        const pageUrl = nextProps.match.params.page * 48 - 48;
        const getUrl = apiUrl + "P" + pageUrl;
        axios.get(getUrl).then((hedgieArchive) => {
          console.log(hedgieArchive);
          this.setState({
            loading: false,
            pageMeta: hedgieArchive.data.pageMeta,
            breadcrumbs: hedgieArchive.data.breadcrumbs,
            hedgies: hedgieArchive.data.hedgies,
            paginate: hedgieArchive.data.paginate[0],
          });
          this.handlePathChange();
        });
      }
    }
  }

  render() {
    const loading = this.state.loading;

    const paginationLink = `/hedgehogs-for-sale${
      this.props.category ? "/category/picked-up" : ""
    }`;
    console.log(paginationLink);

    return (
      <Fragment>
        <Container>
          {loading ? (
            <Loader />
          ) : (
            <Fragment>
              <Segment basic style={{ padding: `3em 0` }}>
                <Grid>
                  <Grid.Column width={16}>
                    <Header
                      as="h1"
                      className="upper"
                      color="default"
                      href=""
                      size="large"
                    >
                      <Header.Content>Picked Up Hedgehogs</Header.Content>
                    </Header>
                    <p>
                      The hedgehogs below have been picked up and taken home.
                    </p>
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column width={16}>
                    <Card.Group
                      itemsPerRow={4}
                      doubling
                      stackable
                      className="hedgieTeaser"
                    >
                      {this.state.hedgies.map((hedgie, index) => (
                        <HedgieCard hedgie={hedgie} key={hedgie.id} />
                      ))}
                    </Card.Group>
                  </Grid.Column>
                </Grid>
                <Grid centered>
                  <Grid.Column textAlign="center">
                    {this.state.paginate.pages ? (
                      <Menu compact>
                        {this.state.paginate.pages.map((page) => {
                          var pageNumber;
                          var pageText;
                          var currentPage = this.state.paginate.currentPage;
                          if (page.type === "firstItem") {
                            pageNumber = 1;
                            pageText = "angle double left";
                          } else if (page.type === "lastItem") {
                            pageNumber = this.state.paginate.totalPages;
                            pageText = "angle double right";
                          } else if (page.type === "prevItem") {
                            pageNumber = currentPage - 1;
                            pageText = "angle left";
                          } else if (page.type === "nextItem") {
                            pageNumber = currentPage + 1;
                            pageText = "angle right";
                          } else {
                            pageNumber = page.number;
                          }
                          return (
                            <Menu.Item
                              as={Link}
                              to={`${paginationLink}/${
                                pageNumber > 1 ? pageNumber : ""
                              }`}
                              type={page.type}
                              className={
                                pageNumber === currentPage &&
                                page.type === "pageItem"
                                  ? "active"
                                  : null
                              }
                            >
                              {pageText ? <Icon name={pageText} /> : pageNumber}
                            </Menu.Item>
                          );
                        })}
                      </Menu>
                    ) : null}
                  </Grid.Column>
                </Grid>
              </Segment>
            </Fragment>
          )}
        </Container>
      </Fragment>
    );
  }
}

export default HedgieArchive;
