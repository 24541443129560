import React, { Component } from "react";
import HelmetMeta from "./HelmetMeta";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import ProductGallery from "./ProductGallery";
import CartFeatures from "./CartFeatures";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Responsive,
} from "semantic-ui-react";
import ProductsGrid from "./ProductsGrid";
import WaitingLists from "./WaitingLists";
//import ProductReviewBadge from "./ProductReviewBadge";

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMeta: { headerBgColor: "gradient-warm", title: "Supplies" },
      breadcrumbs: [{ content: "Home" }, { content: "Supplies" }],
    };
  }

  handlePathChange = () => {
    var crumbs = this.state.breadcrumbs;
    this.props.onPathChange(crumbs);
  };

  componentDidMount() {
    const product = this.props.product;
    const handle = this.props.handle;
    axios.get(`https://admin.suncoasthedgehogs.com/supplies`).then((eeData) => {
      this.setState({
        pageMeta: eeData.data.pageMeta[0],
        breadcrumbs: [
          { content: "Home", key: "home", href: "/" },
          { content: "Supplies", key: "supplies", href: "/supplies" },
          {
            content: product.title,
            key: product.handle,
            href: `/supplies/${handle}`,
          },
        ],
      });
      this.handlePathChange();
    });

    // let script = document.createElement("script");
    // script.setAttribute("id", "stampedIoEmbed");
    // script.onload = function () {
    //   let newScript = document.createElement("script");
    //   let inlineScript = document.createTextNode(
    //     'StampedFn.init({apiKey : "pubkey-1o0SDS2eNfc5g0297rTufg4wJWTx12",storeUrl : "sun-coast-hedgehogs-test.myshopify.com"});'
    //   );
    //   newScript.setAttribute("id", "stampedIoWidget");
    //   newScript.appendChild(inlineScript);
    //   document.getElementsByTagName("head")[0].appendChild(newScript);
    // };
    // script.src = "https://cdn1.stamped.io/files/widget.min.js";
    // document.getElementsByTagName("head")[0].appendChild(script);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.handle !== this.props.handle) {
      const product = this.props.product;
      this.setState({
        pageMeta: {},
        breadcrumbs: [
          { content: "Home", key: "home", href: "/" },
          { content: "Supplies", key: "supplies", href: "/supplies" },
        ],
      });
      axios
        .get(`https://admin.suncoasthedgehogs.com/supplies`)
        .then((eeData) => {
          this.setState({
            pageMeta: eeData.data.pageMeta[0],
            breadcrumbs: [
              { content: "Home", key: "home", href: "/" },
              { content: "Supplies", key: "supplies", href: "/supplies" },
              {
                content: product.title,
                key: product.handle,
                href: `/supplies/${this.props.handle}`,
              },
            ],
          });
          this.handlePathChange();
        });
    }
  }

  // componentWillUnmount() {
  //   let stampedIoEmbed = document.getElementById("stampedIoEmbed");
  //   let stampedIoWidget = document.getElementById("stampedIoWidget");
  //   if (stampedIoEmbed && stampedIoWidget) {
  //     stampedIoEmbed.remove();
  //     stampedIoWidget.remove();
  //   }
  // }

  render() {
    const { product, addVariant } = this.props;

    const meta = [
      {
        title: `${product.title} - Supplies - Sun Coast Hedgehogs`,
        titleSEO: `${product.title} - Supplies - Sun Coast Hedgehogs`,
        titleOG: `${product.title} - Supplies - Sun Coast Hedgehogs`,
        descSEO: product.description,
        descOG: product.description,
        imgOG: product.images.edges[0].src,
      },
    ];

    return (
      <div>
        <HelmetMeta meta={meta[0]} />
        <Container>
          <Grid padded="vertically">
            <Grid.Row className="productDetails supplyDetails">
              <Grid.Column mobile={16} tablet={7} computer={7}>
                <ProductGallery images={product.images.edges} />
                <Responsive as={Divider} hidden />
              </Grid.Column>
              <Grid.Column mobile={0} tablet={0} computer={1} />
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Header as="h1" size="tiny" className="productSEO">
                  {product.title} - {product.productType} for Hedgehogs
                </Header>
                <Header as="h2" className="productName">
                  {product.title}
                </Header>

                {/* <ProductReviewBadge productid={product.id} /> */}

                <CartFeatures product={product} addVariant={addVariant} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <Header as="h3" size="large" textAlign="center" dividing>
                  Description
                </Header>
                {product.descriptionHtml ? (
                  ReactHtmlParser(product.descriptionHtml)
                ) : (
                  <p>This Product doesn't have a description.</p>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider style={{ marginTop: "3em " }} />

          <Container style={{ padding: "2em 0" }}>
            <Header as="h3" size="large" className="upper">
              Recommended Supplies
            </Header>
            <ProductsGrid handle="supplies" stars={true} limit={4} />
          </Container>
          <Container textAlign="center">
            <Button as={Link} to="/supplies" size="huge">
              See All Hedgehog Supplies
            </Button>
          </Container>

          <Divider hidden style={{ marginTop: "3em " }} />

          <Container style={{ padding: "2em 0" }}>
            <WaitingLists />
          </Container>

          <Divider hidden style={{ marginTop: "3em " }} />
        </Container>

        <Container fluid>
          <Divider hidden style={{ marginTop: "3em " }} />
        </Container>
      </div>
    );
  }
}

export default ProductView;
