import React, { Component } from "react";
import { Header, Reveal } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

class BloqReveal extends Component {
  render() {
    const data = this.props.data;

    return (
      <Reveal animated="move" className={`revealBox reveal-${data.color}`}>
        <Reveal.Content visible>
          <Header size={data.visibleContentSize}>
            {ReactHtmlParser(data.visibleContent)}
          </Header>
        </Reveal.Content>
        <Reveal.Content hidden>
          <Header size={data.hiddenContentSize}>
            {ReactHtmlParser(data.hiddenContent)}
          </Header>
        </Reveal.Content>
      </Reveal>
    );
  }
}

export default BloqReveal;
