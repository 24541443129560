import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Image, Input } from "semantic-ui-react";

class CartPageLineItem extends Component {
  constructor(props) {
    super(props);
    const { item } = this.props;
    this.state = { value: item.node.quantity };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    this.props.updateVariantQuantity(
      this.props.item.node.variant.id,
      event.target
    );
  }

  render() {
    const { item, removeVariant } = this.props;
    return (
      <tr className="cartItem">
        <td>
          <Link
            to={`/${
              item.node.variant.product.productType === "Hedgehog"
                ? "hedgehogs-for-sale"
                : "supplies"
            }/${item.node.variant.product.handle}`}
          >
            <span>{item.node.title}</span>
            <Image
              src={item.node.variant.image.transformedSrc}
              alt={item.node.title}
              size="small"
              className="photoTrim compact"
            />
          </Link>
        </td>
        <td>${item.node.variant.price.amount * 1}</td>
        <td>
          {item.node.variant.product.productType === "Hedgehog" ? (
            <Fragment>
              {this.state.value}
              <input
                type="hidden"
                className="cartVar"
                value={item.node.variant.id}
              />
              <input
                type="hidden"
                className="cartQty"
                min="1"
                value={this.state.value}
              />
            </Fragment>
          ) : (
            <Fragment>
              <input
                type="hidden"
                className="cartVar"
                value={item.node.variant.id}
              />
              <Input
                type="number"
                className="cartQty"
                min="1"
                value={this.state.value}
                onChange={this.handleChange}
              />
            </Fragment>
          )}
        </td>
        <td className="columnBreak"></td>
        <td>${item.node.variant.price.amount * item.node.quantity}</td>
        <td>
          <Button
            icon
            compact
            size="mini"
            onClick={() => removeVariant(item.node.variant.id)}
          >
            <Icon
              name="close"
              aria-label={`Remove ${item.node.title} from your cart`}
            />
          </Button>
        </td>
      </tr>
    );
  }
}

export default CartPageLineItem;
