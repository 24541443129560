import React from "react";
import Slider from "react-slick";
import { Container, Image } from "semantic-ui-react";

class ProductGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderMain: null,
      sliderNav: null,
    };
  }

  componentDidMount() {
    this.setState({
      sliderMain: this.sliderMain,
      sliderNav: this.sliderNav,
    });
  }

  render() {
    const settingsMain = {
      arrows: false,
    };
    const settingsNav = {
      arrows: true,
    };

    const { images } = this.props;
    var imgCount = images.length;
    if (imgCount === 1) {
      return (
        <Container fluid padded textAlign="center">
          {images.map((image, index) => (
            <Image
              className="photoTrim"
              src={image.node ? image.node.productImg : image.url}
              alt={image.altText}
              key={`thumbnail-${index}`}
            />
          ))}
        </Container>
      );
    } else {
      return (
        <Container fluid padded textAlign="center">
          <Slider
            {...settingsMain}
            className="mainSlider"
            asNavFor={this.state.sliderNav}
            ref={(slider) => (this.sliderMain = slider)}
          >
            {images.map((image, index) => (
              <Image
                src={image.node ? image.node.productImg : image.url}
                alt={image.altText}
                key={`thumbnail-${index}`}
              />
            ))}
          </Slider>
          <Slider
            {...settingsNav}
            className="navSlider"
            asNavFor={this.state.sliderMain}
            ref={(slider) => (this.sliderNav = slider)}
            slidesToShow={imgCount < 5 ? imgCount : 5}
            swipeToSlide={true}
            focusOnSelect={true}
          >
            {images.map((image, index) => (
              <div className="galleryNavItem">
                <Image
                  src={image.node ? image.node.thumbnailImg : image.url}
                  alt={image.altText}
                  key={`thumbnail-${index}`}
                />
              </div>
            ))}
          </Slider>
        </Container>
      );
    }
  }
}

export default ProductGallery;
