import React, { Component } from "react";
import BloqLoopHedgies from "./BloqLoopHedgies";
import BloqLoopLitters from "./BloqLoopLitters";
import BloqLoopWaitlist from "./BloqLoopWaitlist";
import BloqLoopBlog from "./BloqLoopBlog";

class BloqLoopHandler extends Component {
  render() {
    const bloq = this.props.data;
    switch (bloq.channel) {
      case "blog":
        return <BloqLoopBlog data={bloq.items} />;
      case "hedgies_for_sale": /* Hedgies - for sale */
      case "hedgies_herd": /* Hedgies - Herd */
      case "hedgies_rescues": /* Hedgies - Rehomes and Rescues */
      case "hedgies_other": /* Hedgies - Other */
      case "hedgies_for_sale|hedgies_rescues" /* Hedgies - For Sale && Rehomes and Rescues */:
        return <BloqLoopHedgies channel={bloq.channel} data={bloq.items} />;
      case "litters":
        return <BloqLoopLitters data={bloq} />;
      case "waitlist_slots":
        return <BloqLoopWaitlist data={bloq} />;
      default:
        return <p>The requested content could not be found.</p>;
    }
  }
}

export default BloqLoopHandler;
