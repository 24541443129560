import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Modal, Segment } from "semantic-ui-react";

import icon_starterkit from "../images/icon-starterkit.png";

class CartPopup extends Component {
  render() {
    const { open, item, type, cartModalClose } = this.props;
    return (
      <Modal
        size="tiny"
        open={open}
        item={item}
        onClose={cartModalClose}
        closeIcon
      >
        <Modal.Header>{item} was added to your added to cart.</Modal.Header>

        {type === "Hedgehog" ? (
          <Fragment>
            <Modal.Content>
              <Segment inverted color="yellow" className="warning">
                <Grid verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column
                      mobile={3}
                      tablet={5}
                      computer={5}
                      textAlign="center"
                    >
                      <Icon
                        name="exclamation"
                        circular
                        inverted
                        fitted
                        color="teal"
                        className="gradient-cool angled fluid"
                      />
                    </Grid.Column>
                    <Grid.Column mobile={13} tablet={11} computer={11}>
                      <p>
                        <strong>WARNING:</strong> Before you purchase a
                        hedgehog, please note that all purchases are
                        NON-REFUNDABLE. Please read our{" "}
                        <Link to="/about/policies">Policies Page</Link> before
                        making a purchase.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Grid padded>
                <Grid.Column
                  textAlign="center"
                  mobile={16}
                  tablet={8}
                  computer={8}
                  className="collapsed"
                >
                  <Button
                    as={Link}
                    icon="shopping basket"
                    labelPosition="right"
                    size="tiny"
                    secondary
                    to="/hedgehogs-for-sale"
                    content="Continue Shopping"
                    onClick={cartModalClose}
                  />
                </Grid.Column>
                <Grid.Column
                  textAlign="center"
                  mobile={16}
                  tablet={8}
                  computer={8}
                  className="collapsed"
                >
                  <Button
                    as={Link}
                    labelPosition="right"
                    size="tiny"
                    secondary
                    to="/supplies"
                    onClick={cartModalClose}
                    className="icon icon-image"
                  >
                    <img src={icon_starterkit} className="icon image" />
                    Add a Starter Kit
                  </Button>
                </Grid.Column>
                <Grid.Column
                  textAlign="center"
                  mobile={16}
                  className="collapsed"
                >
                  <Button
                    as={Link}
                    to="/cart"
                    icon="cart"
                    labelPosition="right"
                    size="big"
                    content="Proceed to Checkout"
                    onClick={cartModalClose}
                  />
                </Grid.Column>
              </Grid>
            </Modal.Actions>
          </Fragment>
        ) : (
          <Fragment>
            <Modal.Actions>
              <Button
                as={Link}
                size="tiny"
                to="/hedgehogs-for-sale"
                onClick={cartModalClose}
              >
                Continue Shopping
              </Button>
              <Button
                as={Link}
                to="/cart"
                icon="cart"
                labelPosition="right"
                size="big"
                content="Proceed to Checkout"
                onClick={cartModalClose}
              />
            </Modal.Actions>
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default CartPopup;
