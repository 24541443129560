import React, { Component } from "react";
import BloqSection from "./BloqSection";
import BloqRow from "./BloqRow";
import BloqColumn from "./BloqColumn";
import BloqHeader from "./BloqHeader";
import BloqRTE from "./BloqRTE";
import BloqCode from "./BloqCode";
import BloqImage from "./BloqImage";
import BloqButton from "./BloqButton";
import BloqDivider from "./BloqDivider";
import BloqTabWrapper from "./BloqTabWrapper";
import BloqAccordion from "./BloqAccordion";
import BloqAnnouncement from "./BloqAnnouncement";
import BloqReveal from "./BloqReveal";
import WaitingLists from "../components/WaitingLists";
import BloqReviews from "./BloqReviews";
import ProductsGrid from "../components/ProductsGrid";
import BloqLoopHandler from "./BloqLoopHandler";
import BloqImageGrid from "./BloqImageGrid";

class BloqHandler extends Component {
  render() {
    const bloq = this.props.data;
    switch (bloq.bloqType) {
      case "section":
        return <BloqSection data={bloq} />;
      case "row":
        return <BloqRow data={bloq} />;
      case "column":
        return <BloqColumn data={bloq} />;
      case "divider":
        return <BloqDivider data={bloq} />;
      case "header":
        return <BloqHeader data={bloq} />;
      case "rte":
        return <BloqRTE data={bloq} />;
      case "code":
        return <BloqCode data={bloq} />;
      case "image":
        return <BloqImage data={bloq} />;
      case "button":
        return <BloqButton data={bloq} />;
      case "imageGrid":
        return <BloqImageGrid data={bloq} />;
      case "loop":
        return <BloqLoopHandler data={bloq} />;
      case "tabs_wrapper":
        return <BloqTabWrapper data={bloq} />;
      case "tab":
        return null;
      case "accordion":
        return <BloqAccordion data={bloq} />;
      case "announcement":
        return <BloqAnnouncement data={bloq} />;
      case "reveal":
        return <BloqReveal data={bloq} />;
      case "waitlist":
        return <WaitingLists />;
      case "reviews":
        return <BloqReviews data={bloq} />;
      case "shopify":
        return (
          <ProductsGrid
            handle={bloq.collection}
            stars={true}
            limit={bloq.limit}
            fallback={
              bloq.fallback ? bloq.fallback : "No hedgehogs matched the query."
            }
          />
        );
      default:
        console.log(
          "Tried to render a bloq that doesn't have a component. Bloq type: " +
            bloq.bloqType
        );
    }
  }
}

export default BloqHandler;
