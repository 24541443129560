//import { useBooleanKnob } from "@stardust-ui/docs-components";
import React, { Component } from "react";
//import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { graphql } from "react-apollo";
import flowright from "lodash.flowright";

import { Segment, Sidebar } from "semantic-ui-react";

import Cart from "./components/Cart";
import Page from "./components/Page";
import CartPage from "./components/CartPage";
import CartPopup from "./components/CartPopup";
import ColorChartPage from "./components/ColorChartPage";
import BlogLayout from "./layouts/BlogLayout";
import HedgieLayout from "./layouts/HedgieLayout";
import ProductLayout from "./layouts/ProductLayout";
import Masthead from "./components/Masthead";
import Footer from "./components/Footer";

import { createCheckout, checkoutLineItemsReplace } from "./checkout";

class App extends Component {
  constructor() {
    super();
    this.state = {
      checkout: {},
      lineItems: [],
      pendingLineItems: [],
      cartOpen: false,
      cartModal: false,
      cartItemName: "",
      cartItemType: "",
      globals: {
        stats: [
          {
            admin: "false",
            lastUpdate: "",
          },
        ],
        babyAlert: [{ display: false }],
      },
    };

    this.addVariant = this.addVariant.bind(this);
    this.removeVariant = this.removeVariant.bind(this);
    this.updateVariantQuantity = this.updateVariantQuantity.bind(this);
    this.pushVariantQuantity = this.pushVariantQuantity.bind(this);
    this.toggleCart = this.toggleCart.bind(this);
    this.clearCart = this.clearCart.bind(this);
  }

  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });
  toggleCart = () => this.setState({ sidebarOpened: true });

  toggleCart() {
    this.setState({ cartOpen: !this.state.cartOpen });
  }

  cartModalOpen = () => this.setState({ cartModal: true });
  cartModalClose = () => this.setState({ cartModal: false });

  addVariant(variantId, productType, productName) {
    const lineItems = this.state.storedLineItems.find(
      (item) => item.variantId === variantId
    )
      ? this.state.storedLineItems.map((item) => ({
          ...item,
          quantity:
            item.variantId === variantId && productType !== "Hedgehog"
              ? item.quantity + 1
              : item.quantity,
          // customAttributes: {
          //   key: "Preferences",
          //   value: "",
          // },
        }))
      : [
          ...this.state.storedLineItems,
          {
            variantId: variantId,
            quantity: 1,
            // customAttributes: {
            //   key: "Preferences",
            //   value: "",
            // },
          },
        ];

    this.props
      .checkoutLineItemsReplace({
        variables: {
          checkoutId: this.state.checkout.id,
          lineItems: lineItems,
        },
      })
      .then((res) =>
        this.setState({
          checkout: res.data.checkoutLineItemsReplace.checkout,
          lineItems: res.data.checkoutLineItemsReplace.checkout.lineItems.edges,
          cartItemType: productType,
          cartItemName: productName,
          cartModal: true,
        })
      );
  }

  removeVariant(variantId) {
    const lineItems = this.state.storedLineItems;
    const updatedLineItems = lineItems.filter(
      (item) => item.variantId !== variantId
    );
    console.log(lineItems);
    console.log(updatedLineItems);

    this.props
      .checkoutLineItemsReplace({
        variables: {
          checkoutId: this.state.checkout.id,
          lineItems: updatedLineItems,
        },
      })
      .then((res) =>
        this.setState({
          checkout: res.data.checkoutLineItemsReplace.checkout,
          lineItems: res.data.checkoutLineItemsReplace.checkout.lineItems.edges,
        })
      );
  }

  updateVariantQuantity(variantId, target) {
    const withUpdatedQuantities = this.state.storedLineItems.find(
      (item) => item.variantId === variantId
    )
      ? this.state.storedLineItems.map((item) => ({
          ...item,
          quantity:
            item.variantId === variantId
              ? parseInt(target.value)
              : item.quantity,
        }))
      : [...this.state.storedLineItems, { variantId: variantId, quantity: 1 }];

    this.setState({
      pendingLineItems: withUpdatedQuantities,
    });
  }

  pushVariantQuantity() {
    const withUpdatedQuantities = this.state.pendingLineItems;

    this.props
      .checkoutLineItemsReplace({
        variables: {
          checkoutId: this.state.checkout.id,
          lineItems: withUpdatedQuantities,
        },
      })
      .then((res) =>
        this.setState({
          checkout: res.data.checkoutLineItemsReplace.checkout,
          lineItems: res.data.checkoutLineItemsReplace.checkout.lineItems.edges,
        })
      );
  }

  clearCart() {
    this.props
      .checkoutLineItemsReplace({
        variables: {
          checkoutId: this.state.checkout.id,
          lineItems: [],
        },
      })
      .then((res) =>
        this.setState({
          checkout: res.data.checkoutLineItemsReplace.checkout,
          lineItems: res.data.checkoutLineItemsReplace.checkout.lineItems.edges,
        })
      );
    alert("Cart Cleared!");
  }

  cartQuantity() {
    return this.state.lineItems.reduce((acc, next) => {
      acc += next.node.quantity;
      return acc;
    }, 0);
  }

  addLineItemsToStorage(lineItems) {
    const linItemsMapped = lineItems.map((item) => ({
      variantId: item.node.variant.id,
      quantity: item.node.quantity,
    }));
    localStorage.setItem("sch_checkout_items", JSON.stringify(linItemsMapped));
    return linItemsMapped;
  }

  removeLineItemsFromStorage() {
    // maybe?
  }

  componentDidMount() {
    //ReactGA.initialize("UA-57035001-1");
    //ReactGA.pageview(window.location.pathname + window.location.search);

    this.props
      .createCheckout({
        variables: {
          input: {},
        },
      })
      .then((res) => {
        this.setState({
          checkout: res.data.checkoutCreate.checkout,
        });
      });

    const lineItems = localStorage.sch_checkout_items
      ? JSON.parse(localStorage.getItem("sch_checkout_items"))
      : [];

    this.props
      .createCheckout({
        variables: {
          input: { lineItems },
        },
      })
      .then((res) =>
        this.setState({
          checkout: res.data.checkoutCreate.checkout,
          lineItems: res.data.checkoutCreate.checkout.lineItems.edges,
        })
      );

    axios
      .get(`https://admin.suncoasthedgehogs.com/eeData/globals`)
      .then((globals) => {
        this.setState({
          globals: globals.data,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    //ReactGA.pageview(window.location.pathname + window.location.search);
    if (prevState.lineItems !== this.state.lineItems) {
      const storedLineItems = this.addLineItemsToStorage(this.state.lineItems);
      this.setState({ storedLineItems: storedLineItems });
    }
  }

  render() {
    const tagManagerArgs = {
      gtmId: "GTM-W5B76GH",
    };
    TagManager.initialize(tagManagerArgs);
    const { sidebarOpened } = this.state;

    return (
      <React.Fragment>
        <Router>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              Jacksonville, Florida Hedgehogs for Sale - Sun Coast Hedgehogs -
              Florida Hedgehog Breeder
            </title>
            <link rel="canonical" href="https://suncoasthedgehogs.com" />
          </Helmet>
          <CartPopup
            open={this.state.cartModal}
            item={this.state.cartItemName}
            type={this.state.cartItemType}
            cartModalClose={this.cartModalClose}
          />
          <Sidebar.Pushable as={Segment}>
            <Sidebar.Pusher dimmed={sidebarOpened}>
              <Masthead
                toggleCart={this.toggleCart}
                cartOpen={this.state.cartOpen}
                cartQuantity={this.cartQuantity()}
                globals={this.state.globals}
              />
              <Switch>
                <Redirect
                  from="/hedgehogs-for-sale/pricing"
                  to="/about/pricing-list"
                />
                <Redirect
                  from="/hedgehogs-for-sale/scheduled-litters"
                  to="/upcoming-litters"
                />
                <Route
                  exact
                  path="/"
                  render={(props) => <Page {...props} handle="/homepage" />}
                />
                <Route exact path="/colors" component={ColorChartPage} />
                <Route
                  exact
                  path="/cart"
                  render={(props) => (
                    <CartPage
                      {...props}
                      clearCart={this.clearCart}
                      checkout={this.state.checkout}
                      removeVariant={this.removeVariant}
                      updateVariantQuantity={this.updateVariantQuantity}
                      pushVariantQuantity={this.pushVariantQuantity}
                    />
                  )}
                />
                <Route
                  path="/blog"
                  render={(props) => <BlogLayout {...props} />}
                />
                {/* <Route exact path="/blog/test" component={BlogLayout} /> */}
                {/* <Route exact path="/blog/:slug" component={BlogView} /> */}
                <Route
                  path="/hedgehogs-for-sale"
                  render={(props) => (
                    <HedgieLayout {...props} addVariant={this.addVariant} />
                  )}
                />
                <Route
                  path="/our-hedgehogs"
                  render={(props) => (
                    <HedgieLayout {...props} addVariant={this.addVariant} />
                  )}
                />
                {/* <Route
                  exact
                  path="/hedgies/:handle"
                  render={(props) => (
                    <Hedgie {...props} addVariant={this.addVariant} />
                  )}
                /> */}
                {/* <Route exact path="/supplies" component={ProductList} /> */}
                <Route
                  path="/supplies"
                  render={(props) => (
                    <ProductLayout {...props} addVariant={this.addVariant} />
                  )}
                />
                <Route
                  path="/:handle/:handle2?/:handle3?/:handle4?/:handle5?"
                  component={Page}
                />
                {/* <Route
                  exact
                  path="/supplies/:handle"
                  render={(props) => (
                    <Product {...props} addVariant={this.addVariant} />
                  )}
                /> */}
              </Switch>
              <Footer globals={this.state.globals} />
            </Sidebar.Pusher>
            <Sidebar
              as={Segment}
              animation="overlay"
              inverted
              onHide={this.handleSidebarHide}
              vertical
              visible={sidebarOpened}
            >
              <Cart
                checkout={this.state.checkout}
                clearCart={this.clearCart}
                toggleCart={this.toggleCart}
                cartOpen={this.state.cartOpen}
              />
            </Sidebar>
          </Sidebar.Pushable>
        </Router>
      </React.Fragment>
    );
  }
}

const AppWithData = flowright(
  graphql(createCheckout, { name: "createCheckout" }),
  graphql(checkoutLineItemsReplace, { name: "checkoutLineItemsReplace" })
)(App);

export default AppWithData;
