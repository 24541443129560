import React, { Component } from "react";
import { Grid, Segment } from "semantic-ui-react";
import BloqHandler from "./BloqHandler";

class BloqSection extends Component {
  render() {
    const data = this.props.data;
    var fluid = 0;
    var isChildSection = 0;
    if (data.width === "fluid") {
      fluid = 1;
    }
    if (data.parentBlock === "section") {
      isChildSection = 1;
    }

    return (
      <div
        className={`${fluid ? "" : "ui container"} ${
          isChildSection ? "childSection" : ""
        }`}
      >
        <Segment
          basic
          className={data.textColor === "inverted" ? "segmentInverted" : ""}
          style={{
            padding: `${data.padding}`,
            backgroundColor: `${data.backgroundColor}`,
            backgroundImage: `url(${data.backgroundImage})`,
            backgroundPosition: `${data.backgroundPosition}`,
            backgroundSize: `${
              data.backgroundScale === "repeat" ? "auto" : data.backgroundScale
            }`,
            backgroundRepeat: `${
              data.backgroundScale !== "repeat"
                ? "no-repeat"
                : data.backgroundScale
            }`,
          }}
          key={data.bloqId}
        >
          {data.nested ? (
            <Grid centered container verticalAlign={data.columnAlign}>
              {data.nested.map((bloq) => (
                <BloqHandler data={bloq} key={bloq.bloqId} />
              ))}
            </Grid>
          ) : (
            <Segment basic>
              <p>This section has no children</p>
            </Segment>
          )}
        </Segment>
      </div>
    );
  }
}

export default BloqSection;
