import React, { Component } from "react";

import { Button, Header, Icon, Image, Segment, Table } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

class BloqLoopWaitlist extends Component {
  render() {
    const waitlist = this.props.data.items;

    if (waitlist[0]) {
      if (waitlist[0].no_results) {
        return (
          <Segment inverted color="yellow">
            <p>{waitlist[0].no_results}</p>
          </Segment>
        );
      } else {
        return (
          <Table celled textAlign="center" className="waitlistTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Notes</Table.HeaderCell>
                <Table.HeaderCell>VIP?</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {waitlist.map((slot) => (
                <Table.Row key={slot.id}>
                  <Table.Cell>{slot.title}</Table.Cell>
                  <Table.Cell>{ReactHtmlParser(slot.notes)}</Table.Cell>
                  <Table.Cell>{slot.vip}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        );
      }
    } else {
      return <p>loading</p>;
    }
  }
}

export default BloqLoopWaitlist;
