import React, { Component } from "react";
import { Grid, Header, Segment } from "semantic-ui-react";

class Instagram extends Component {
  componentDidMount() {
    let script = document.createElement("script");
    script.setAttribute("id", "covetPicsEmbed");
    script.onload = function () {
      let newScript = document.createElement("script");
      newScript.setAttribute("id", "covetPicsWidget");
      document.getElementsByTagName("head")[0].appendChild(newScript);
    };
    script.src = "https://shopify.covet.pics/covet-pics-widget-inject.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  componentWillUnmount() {
    let covetPicsEmbed = document.getElementById("covetPicsEmbed");
    let covetPicsWidget = document.getElementById("covetPicsWidget");
    if (covetPicsEmbed && covetPicsWidget) {
      covetPicsEmbed.remove();
      covetPicsWidget.remove();
    }
  }

  render() {
    return (
      <div style={{ padding: "3em 0" }}>
        {this.props.showHeader ? (
          <Header
            as="h2"
            size="huge"
            color="red"
            textAlign="center"
            className="brush"
          >
            Follow Us on Instagram!
          </Header>
        ) : null}
        <Segment basic>
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                <covet-pics-widget gallery-embed-id="188558"></covet-pics-widget>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default Instagram;
