import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class BloqCode extends Component {
  render() {
    const data = this.props.data;

    return <div className="bloq_code">{ReactHtmlParser(data.content)}</div>;
  }
}

export default BloqCode;
