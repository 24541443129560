import React from "react";
import { Container } from "semantic-ui-react";
import CartLineItem from "../components/CartLineItem";

const Cart = (props) => {
  const { checkout, clearCart } = props;
  return (
    <Container>
      <header className="Cart__header">
        <h2>Cart</h2>
        {/* <Button icon onClick={toggleCart}>
          <Icon name="close" />
        </Button> */}
      </header>
      {checkout.lineItems ? (
        <ul className="Cart__line-items">
          {checkout.lineItems.edges.map((item, index) => (
            <CartLineItem item={item.node} key={`line-item-${index}`} />
          ))}
        </ul>
      ) : (
        <div>Loading...</div>
      )}
      <footer className="Cart__footer">
        <a href={checkout.webUrl} className="button">
          Checkout
        </a>
        &nbsp;
        <span onClick={clearCart} className="button">
          Clear Cart
        </span>
      </footer>
    </Container>
  );
};

export default Cart;
